import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['ButtonDialog_address', 'ButtonDialog_ignore'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Img = makeShortcode("Img");
const Paper = makeShortcode("Paper");
const ButtonDialog = makeShortcode("ButtonDialog");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ground-rules"
    }}>{`Ground rules`}</h1>
    <Grid container spacing={3} mdxType="Grid">
  <Grid item xs mdxType="Grid">
    <h4>3. Listen well.</h4>
    <p>
      Listening is a skill that benefits communication in every relationship, not just the one you have with your
      teenager. The trick is to focus on what the other person is saying, not on what you will say when it’s your turn
      to speak. Listen for what might be right in what the other person is saying, not the things that are wrong.
    </p>
  </Grid>
  <Grid item xs={12} md={6} mdxType="Grid">
    <Img src="/images/m5/21.svg" alt="listening to child" mdxType="Img" />
  </Grid>
    </Grid>
    <h4 {...{
      "id": "4-take-responsibility"
    }}>{`4. Take responsibility.`}</h4>
    <p>{`Take responsibility for your actions. Parents can offend and upset their kids. It’s easy to blame the other person and justify your reactions, especially when the other person has made you angry. However, becoming defensive will only make conflict worse. Remember that how you handle conflict is a powerful example for your teenager. Rather than applying the ‘silent treatment’ or stewing in resentment, be the one to approach your teenager and apologise for any anger or frustration you have caused them. Talk with them about what you should have done instead of losing your temper. Show that you are willing to make the effort to work things out.`}</p>
    <h4 {...{
      "id": "5-choose-your-battles"
    }}>{`5. Choose your battles.`}</h4>
    <p>{`Decide if the issue is really worth addressing. Sometimes acceptance is okay. No one wants to be in conflict all the time, or over everything. If you nag your teenager or pick on everything they do, you risk losing credibility for when there is something that really needs to be addressed.`}</p>
    <Paper mdxType="Paper">
  <h5>A quick guide to work out if it’s worth addressing:</h5>
  <Grid container spacing={3} mdxType="Grid">
    <Grid item xs mdxType="Grid">
      <p>Is it a minor irritation that can be ignored?</p>
    </Grid>
    <Grid item mdxType="Grid">
      <ButtonDialog fullScreen={false} linkText="Yes" title="Yes" mdxType="ButtonDialog">
        <p>Ignore it!</p>
      </ButtonDialog>
    </Grid>
    <Grid item mdxType="Grid">
      <ButtonDialog fullScreen={false} linkText="No" title="No" mdxType="ButtonDialog">
        <p>Address it!</p>
      </ButtonDialog>
    </Grid>
  </Grid>
  <Grid container spacing={3} mdxType="Grid">
    <Grid item xs mdxType="Grid">
      <p>Is it concerning my teenager’s safety or wellbeing?</p>
    </Grid>
    <Grid item mdxType="Grid">
      <ButtonDialog fullScreen={false} linkText="Yes" title="Yes" id="address" mdxType="ButtonDialog">
        <p>Address it!</p>
      </ButtonDialog>
    </Grid>
    <Grid item mdxType="Grid">
      <ButtonDialog fullScreen={false} linkText="No" title="No" id="ignore" mdxType="ButtonDialog">
        <p>Ignore it!</p>
      </ButtonDialog>
    </Grid>
  </Grid>
    </Paper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      